// noinspection DuplicatedCode

import apiService from './Base/BaseRepository';

const url = '/members';


export default {
  /**
   * Fetches all members.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async get() {
    return await apiService.get(`${url}/`);
  },

  /**
   * Fetches all members.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getPaginated(perPage = 20, page = 1, sorts = [], filters = [], withTotals = 0, showLoadingIndicator = true,) {

    if (filters.length === 0) {
      filters.push({
        filterKey: 'isAnonymized',
        filterValue: 0
      })
    }
    return await apiService
      .setPagination(perPage, page, withTotals)
      .setSorts(sorts)
      .setFilters(filters)
      .get(`${url}`, {
        showLoadingIndicator
      });
  },

  async getPaginatedForSelect(perPage = 20, page = 1, sorts = [], filters = [], withTotals = 0, showLoadingIndicator = true,) {

    if (filters.length === 0) {
      filters.push({
        filterKey: 'isAnonymized',
        filterValue: 0
      })
    }
    return await apiService
      .setPagination(perPage, page, withTotals)
      .setSorts(sorts)
      .setFilters(filters)
      .get(`${url}/select`, {
        showLoadingIndicator
      });
  },

  async getAllEmployees(onlyActive = false) {
    let filters = [{filterKey: 'memberTypes', filterValue: 'employee'}];

    if (onlyActive) {
      filters = [{filterKey: 'activeMemberTypes', filterValue: 'employee'}];
    }

    return this.getPaginated(
      1000,
      1,
      [],
      filters
    );
  },

  async getAllActiveEmployees() {
    return this.getPaginated(
      1000,
      1,
      [],
      [{filterKey: 'activeMemberTypes', filterValue: 'employee'}
      ]
    );
  },

  /**
   * Get new customer stats
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getNewCustomerStats(year) {
    return await apiService.get(`${url}/stats/newCustomers?year=${year}`);
  },


  /**
   * Fetches a single member.
   *
   * @param memberId
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getSingle(memberId) {
    return await apiService.get(`${url}/${memberId}`);
  },

  /**
   * Anonymize a given Member.
   *
   * @param memberId
   * @returns {Promise<AxiosResponse<any>>}
   */
  async anonymizeSingle(memberId) {
    return await apiService.patch(`${url}/${memberId}/anonymize`);
  },

  /**
   * Creates a new member.
   *
   * @param values
   * @returns {Promise<AxiosResponse<any>>}
   */
  async create(payload) {
    return await apiService.post(`${url}/`, payload);
  },

  /**
   * Updates the given member.
   *
   * @param memberId
   * @param values
   * @returns {Promise<AxiosResponse<any>>}
   */
  async update(memberId, values) {
    return await apiService.patch(`${url}/${memberId}`, values);
  },

  instructions: {
    async getPaginated(memberId, perPage = 20, page = 1, sorts = [], filters = [], withTotal = 0) {
      return await apiService
        .setPagination(perPage, page, withTotal)
        .setSorts(sorts)
        .setFilters(filters)
        .get(`${url}/${memberId}/instruction`);
    },

    async getSingle(memberId, instructionId) {
      return await apiService.get(`${url}/${memberId}/instruction/${instructionId}`);
    },

    async create(memberId, payload) {
      return await apiService.post(`${url}/${memberId}/instruction`, payload);
    },

    async update(memberId, instructionId, payload) {
      return await apiService.patch(`${url}/${memberId}/instruction/${instructionId}`, payload);
    },

    async delete(memberId, instructionId) {
      return await apiService.delete(`${url}/${memberId}/instruction/${instructionId}`);
    },
  },

  batchActions: {
    async sendInvoiceReminder(memberIds) {
      return await apiService.patch(`${url}/batchAction`, {
        jobAction: "sendInvoiceReminders",
        items: memberIds
      });
    },
  },

  async sendInvoiceReminder(memberId) {
    return await apiService.post(`${url}/${memberId}/sendInvoiceReminder`, {});
  },

  async sendAuthLinkEmail(memberId) {
    return await apiService.post(`${url}/${memberId}/sendAuthLink`, {});
  },

  my: {
    async fetchHolidaysForYear(year) {
      return await apiService.get(`/my/holidays/${year}`, {
        showLoadingIndicator: false
      });
    }
  },

  notifications: {
    async markAllAsRead(memberId) {
      return await apiService.patch(`${url}/${memberId}/notifications/markAllRead`);
    }
  }

}
